import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/designer/adobe-illustrator-shortcuts",
  "date": "2013-01-10",
  "title": "ADOBE ILLUSTRATOR SHORTCUTS",
  "author": "admin",
  "tags": ["design", "illustrator"],
  "featuredImage": "feature.jpg",
  "excerpt": "Here are 26 Adobe Illustrator shortcuts that can help you to speed up productivity. I use most of them (in fact, I can’t work without them). Most of shortcuts listed in this article aren’t documented in the software, so keep reading and you’re sure to find at least one new trick to put up your sleeve. Enjoy!"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Message type="info" title="PC" content="This article is written in Mac Illustrator CS3 format. If you are using PC, Cmd key = Ctrl key and Opt = Alt." mdxType="Message" />
    <h2>{`1. Change the Unit Setting`}</h2>
    <p>{`You can quickly change the Artboard unit measurement setting by right-clicking on the ruler.`}</p>
    <p><img parentName="p" {...{
        "src": "http://www.webdesignerwall.com/wp-content/uploads/2008/07/ruler-unit.gif",
        "alt": "Unit Setting"
      }}></img></p>
    <h2>{`2. Activate Tool Options`}</h2>
    <p>{`With the tool selected, (e.g. Blend Tool), press the `}<inlineCode parentName="p">{`Enter`}</inlineCode>{` key to open up the Blend Options window. Another way to open up the tool option panel is to double-click on the tool icon.`}</p>
    <p><img parentName="p" {...{
        "src": "http://www.webdesignerwall.com/wp-content/uploads/2008/07/blend-options.gif",
        "alt": "Blend tool options"
      }}></img></p>
    <h2>{`3. Duplicate a Series of Objects`}</h2>
    <p>{`Select the object, hold down `}<inlineCode parentName="p">{`Option`}</inlineCode>{` key and drag. You now have a copy of the object. Each time you press `}<inlineCode parentName="p">{`Cmd + D`}</inlineCode>{` (Transform Again) you distribute more copies.`}</p>
    <p><img parentName="p" {...{
        "src": "http://www.webdesignerwall.com/wp-content/uploads/2008/07/transform-again.gif",
        "alt": "tranform again"
      }}></img></p>
    <h2>{`4. Draw Shape with Options`}</h2>
    <p>{`Select any shape tool, (e.g. Star Tool), click anywhere on the Artboard, it will let you draw a shape with options.`}</p>
    <img alt="screenshot" src="http://www.webdesignerwall.com/wp-content/uploads/2008/07/star-tool.gif" />
    <h2>{`5. Zoom With Mouse Wheel`}</h2>
    <p>{`Hold down `}<inlineCode parentName="p">{`Option`}</inlineCode>{` key `}{`&`}{` scroll the mouse wheel up or down to zoom in/out`}</p>
    <img alt="mouse wheel" src="http://www.webdesignerwall.com/wp-content/uploads/2008/07/zoom-mouse-wheel.gif" />
    <h2>{`6. Gradient Stop Color`}</h2>
    <p>{`To quickly change a gradient stop color to any color swatch in the library: select a gradient stop, hold down `}<inlineCode parentName="p">{`Option`}</inlineCode>{` key and click on a swatch.`}</p>
    <img alt="screenshot" src="http://www.webdesignerwall.com/wp-content/uploads/2008/07/gradient-stop.gif" />
    <h2>{`6. Switch Color Profile`}</h2>
    <p>{`Hold down `}<inlineCode parentName="p">{`Shift`}</inlineCode>{` key and click on the color bar will let you toggle through the color profiles: Grayscale, RGB, HSB, CMYK, Web Safe RGB.`}</p>
    <h2>{`7. Layer Visibility`}</h2>
    <p><inlineCode parentName="p">{`Cmd + Click`}</inlineCode>{` Visibility Icon = toggle view mode (Outline/Preview)`}</p>
    <p><inlineCode parentName="p">{`Opt + Click`}</inlineCode>{` Visibility Ico = hide other layers (Opt + click again will show all)`}</p>
    <p><inlineCode parentName="p">{`Click Visibility Icon &amp; Drag`}</inlineCode>{` = toggle multi layers`}</p>
    <h2>{`8. New Layer`}</h2>
    <p><inlineCode parentName="p">{`Cmd + L`}</inlineCode>{` = New layer`}</p>
    <p><inlineCode parentName="p">{`Cmd + Opt + L`}</inlineCode>{` = New layer with options`}</p>
    <img alt="new layer" src="http://www.webdesignerwall.com/wp-content/uploads/2008/07/new-layer.gif" />
    <h2>{`9. Select All Objects on Layer`}</h2>
    <p>{`To select all objects on a particular layer, hold down `}<inlineCode parentName="p">{`Option`}</inlineCode>{` key and click on the layer name.`}</p>
    <h2>{`10. Navigate Live Paint Bucket Color`}</h2>
    <p>{`With the Live Paint Bucket tool selected, click on any swatch in the Color palette, now use the arrow keys (`}<inlineCode parentName="p">{`Up`}</inlineCode>{`, `}<inlineCode parentName="p">{`Down`}</inlineCode>{`, `}<inlineCode parentName="p">{`Left`}</inlineCode>{`, `}<inlineCode parentName="p">{`Right`}</inlineCode>{`) to navigate through the swatches.`}</p>
    <p>{`11. ## Fill and Stroke Color`}</p>
    <p><inlineCode parentName="p">{`D`}</inlineCode>{` = Default color (white fill `}{`&`}{` black stroke)`}</p>
    <p><inlineCode parentName="p">{`X`}</inlineCode>{` = Toggle between fill and stroke`}</p>
    <p><inlineCode parentName="p">{`Shift + X`}</inlineCode>{` = Swap fill and stroke color`}</p>
    <p><inlineCode parentName="p">{`/`}</inlineCode>{` = None (transparent)`}</p>
    <p><inlineCode parentName="p">{`<`}</inlineCode>{` = Color fill`}</p>
    <p><inlineCode parentName="p">{`>`}</inlineCode>{` = Gradient fill`}</p>
    <h2>{`12.  Saturate or Desaturate Color`}</h2>
    <p>{`Holding down `}<inlineCode parentName="p">{`Cmd`}</inlineCode>{` key and dragging the color slider will saturate or desaturate a mixed color.`}</p>
    <p><img parentName="p" {...{
        "src": "http://www.webdesignerwall.com/wp-content/uploads/2008/07/color-slider.gif",
        "alt": "color slider"
      }}></img></p>
    <h2>{`13. Increase/Decrease Input Field Value`}</h2>
    <p>{`Place the cursor in any input field, press arrow `}<inlineCode parentName="p">{`Up`}</inlineCode>{` or `}<inlineCode parentName="p">{`Down`}</inlineCode>{` to increase/decrease.`}</p>
    <p><img parentName="p" {...{
        "src": "http://www.webdesignerwall.com/wp-content/uploads/2008/07/input-field.gif",
        "alt": "input field"
      }}></img></p>
    <h2>{`14. Close Pencil or Brush Path`}</h2>
    <p>{`By default, Illustrator will use close a Pencil and Brush path. To close a Pencil path manually, hold down `}<inlineCode parentName="p">{`Option`}</inlineCode>{` key when you are about to finish drawing the path.`}</p>
    <p><img parentName="p" {...{
        "src": "http://www.webdesignerwall.com/wp-content/uploads/2008/07/close-path.gif",
        "alt": "close pencil path"
      }}></img></p>
    <h2>{`15. Add or Subtract Sides`}</h2>
    <p>{`With the Star Tool, drag on the Artboard (do not release the mouse yet), press`}<inlineCode parentName="p">{`Up`}</inlineCode>{` or `}<inlineCode parentName="p">{`Down`}</inlineCode>{` arrow to add/subtract sides. Notes: this shortcut can also works for the Arc, Spiral, Rectangle Grid, Polar Grid, Polygon, and Flare Tool.`}<img alt="add or subtract sides" src="http://www.webdesignerwall.com/wp-content/uploads/2008/07/add-subtract-sides.gif" /></p>
    <h2>{`16. Inner Radius`}</h2>
    <p>{`With the Star Tool, drag on the Artboard (do not release the mouse yet), holding down the `}<inlineCode parentName="p">{`Cmd`}</inlineCode>{` key while dragging will let you control the inner radius. This shortcut also works for the Spiral Tool.`}</p>
    <p><img parentName="p" {...{
        "src": "http://www.webdesignerwall.com/wp-content/uploads/2008/07/inner-radius.gif",
        "alt": "inner radius"
      }}></img></p>
    <h2>{`17. Continuous Duplicates Based on Mouse Movement`}</h2>
    <p>{`With any shape tool, holding down the Tilde (`}<inlineCode parentName="p">{`~`}</inlineCode>{`) key while dragging will make continuous duplicates based on your mouse movement. This is a very useful shortcut to create fun and complex artwork.`}</p>
    <p><img parentName="p" {...{
        "src": "http://www.webdesignerwall.com/wp-content/uploads/2008/07/tilde.gif",
        "alt": "tilde"
      }}></img></p>
    <h2>{`18. Straight Sided Star`}</h2>
    <p>{`With the Star Tool, while dragging holding down the `}<inlineCode parentName="p">{`Option`}</inlineCode>{` key will let you draw a straight side star.`}</p>
    <p><img parentName="p" {...{
        "src": "http://www.webdesignerwall.com/wp-content/uploads/2008/07/straight-side.gif",
        "alt": "star shape"
      }}></img></p>
    <h2>{`19. Lock and Unlock Objects`}</h2>
    <p><inlineCode parentName="p">{`Cmd + 2`}</inlineCode>{` = Lock selected object(s)`}</p>
    <p><inlineCode parentName="p">{`Cmd + Opt + Shift + 2`}</inlineCode>{` = Lock all deselected objects`}</p>
    <p><inlineCode parentName="p">{`Cmd + Opt + 2`}</inlineCode>{` = Unlock all`}</p>
    <p>{`\`## 20. Switch Between Move and Selection Tool`}</p>
    <p><inlineCode parentName="p">{`Cmd + Opt + Tab`}</inlineCode>{` = Switch between Move and Direct Selection Tool.`}</p>
    <p><inlineCode parentName="p">{`Option`}</inlineCode>{` (Direct Selection Tool selected) = toggle to Group Selection Tool.`}</p>
    <p><img parentName="p" {...{
        "src": "http://www.webdesignerwall.com/wp-content/uploads/2008/07/move-selection-tool.gif",
        "alt": "selection tool"
      }}></img></p>
    <h2>{`21. Hide and Show Objects`}</h2>
    <p><inlineCode parentName="p">{`Cmd + Opt + Shift + 3`}</inlineCode>{` = Hide all deselected objects`}</p>
    <p><inlineCode parentName="p">{`Cmd + Opt + 3`}</inlineCode>{` = Show all objects`}</p>
    <h2>{`22. Eyedropper - Sample Photo or Gradient Color`}</h2>
    <p>{`To sample intermediate color from a gradient or photo: with the Eyedropper tool selected, hold down `}<inlineCode parentName="p">{`Shift`}</inlineCode>{` key and click on the photo.`}</p>
    <p><img parentName="p" {...{
        "src": "http://www.webdesignerwall.com/wp-content/uploads/2008/07/eyedropper-color.gif",
        "alt": "eyedropper color"
      }}></img></p>
    <h2>{`23. Make and Release Guide`}</h2>
    <p><inlineCode parentName="p">{`Cmd + 5`}</inlineCode>{` = Convert selected object to Guide`}</p>
    <p><inlineCode parentName="p">{`Cmd + Shift + double-click on Guide`}</inlineCode>{` = Release Guide`}</p>
    <p><img parentName="p" {...{
        "src": "http://www.webdesignerwall.com/wp-content/uploads/2008/07/make-guide.jpg",
        "alt": "make and release guide"
      }}></img></p>
    <h2>{`24. Font: Size, Leading, Kerning and Tracking`}</h2>
    <p><inlineCode parentName="p">{`Cmd + Shift + >`}</inlineCode>{` or `}<inlineCode parentName="p">{`<`}</inlineCode>{` = Increase/decrease font size`}</p>
    <p><inlineCode parentName="p">{`Opt + Up`}</inlineCode>{` or `}<inlineCode parentName="p">{`Down`}</inlineCode>{` = Increase/decrease leading`}</p>
    <p><inlineCode parentName="p">{`Opt + Left`}</inlineCode>{` or `}<inlineCode parentName="p">{`Right`}</inlineCode>{` = Increase/decrease kerning or tracking`}</p>
    <p><inlineCode parentName="p">{`Cmd + Opt + Q`}</inlineCode>{` = Reset kerning/tracking to zero`}</p>
    <h2>{`25. Add New Fill and Stroke`}</h2>
    <p><inlineCode parentName="p">{`Cmd + /`}</inlineCode>{` = Add new fill`}</p>
    <p><inlineCode parentName="p">{`Cmd + Opt + /`}</inlineCode>{` = Add new stroke`}</p>
    <p><img parentName="p" {...{
        "src": "http://www.webdesignerwall.com/wp-content/uploads/2008/07/new-stroke-fill.gif",
        "alt": "new fill and stroke"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      